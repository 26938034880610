import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { 
    API_RESOURCE_CYCLIC_EVENT,
    API_RESOURCE_EVENT,
} from 'Consts/apiResources';
import { TIME_API_FORMAT } from 'Consts/date';

import StyledComponent from 'Components/core/StyledComponent';
import Editor from 'Components/admin/events/EventCycle/Editor';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import EventsList from 'Components/admin/events/List';
import ModalExtendCyclicEvent from 'Components/admin/modals/EventCycleExtend';

import CyclicEvent from 'Models/CyclicEvent';

export const TAB_DATA = 'data';
export const TAB_EVENTS = 'events';

export default class AdminEventCycle extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            createCyclicEvent: PropTypes.func.isRequired,
            singleCyclicEvent: PropTypes.func.isRequired,
            singleEvent: PropTypes.func.isRequired,
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    };
    static defaultProps = {};
    state = {
        originEvent: {},
        cyclicEvent: {},
        isModalExtendCycleVisible: false,
        extendedCyclicEvent: null,
    };

    componentDidMount = () => {
        this.loadOriginEvent();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { cyclicEvent } = this.state;

        if (cyclicEvent && JSON.stringify(cyclicEvent) !== JSON.stringify(prevState.cyclicEvent) && cyclicEvent.hasEvents) {
            this.setState(prevState => ({
                extendedCyclicEvent: {
                    ...prevState.extendedCyclicEvent,
                    ...this.setExtendedEventDates(cyclicEvent),
                },
            }));
        }
    }

    loadOriginEvent = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.singleEvent({
            id: params.id,
        }).then(response => {
            const result = response.payload[API_RESOURCE_EVENT];

            this.setState({
                originEvent: result,
            }, this.loadCyclicEvent);
        })
    }

    loadCyclicEvent = () => {
        const { actions } = this.props;
        const { originEvent } = this.state;

        if (originEvent.cyclicEvent) {
            actions.singleCyclicEvent({
                id: originEvent.cyclicEvent.id,
            }).then(response => {
                const result = new CyclicEvent(response.payload[API_RESOURCE_CYCLIC_EVENT]);
    
                this.setState({
                    cyclicEvent: result,
                });
            })
        } else {
            if (!originEvent.eventCycle) {
                actions.createCyclicEvent({
                    originEventId: originEvent.id,
                    userId: originEvent.user.id,
                    leadId: originEvent.lead?.id,
                }).then(response => {
                    const result = response.payload[API_RESOURCE_CYCLIC_EVENT];
        
                    this.setState({
                        cyclicEvent: result,
                    });
                });
            }
        } 
    }

    setExtendedEventDates = data => ({
        ...data,
        startAt: moment(data.lastEvent.startAt).add(1, 'week'),
        endAt: moment(data?.lastEvent?.startAt).add(3, 'months').add(1, 'week'),
        eventStartTime: moment(data?.lastEvent?.startAt, TIME_API_FORMAT),
        extendCycle: true,
    })

    render() {
        const { history, location } = this.props;
        const { cyclicEvent, isModalExtendCycleVisible, extendedCyclicEvent } = this.state;

        return (
            <StyledComponent
                className="admin-event-cycle"
                styles={require('./styles')}
            >
                <TabNavigation
                    location={location}
                    history={history}
                    headline="Zarządzaj cyklem"
                    controls={[{
                        visible: cyclicEvent.hasEvents,
                        key: 'extendCycle',
                        label: 'Przedłuż cykl',
                        onClick: () => {
                            this.setState({
                                isModalExtendCycleVisible: true,
                            });
                        },
                    }]}
                    tabs={[{
                        key: TAB_DATA,
                        label: 'Dane',
                        children: (
                            <Editor
                                location={location}
                                history={history}
                                cyclicEvent={cyclicEvent}
                            />
                        ),
                    }, {
                        visible: cyclicEvent.hasEvents,
                        key: TAB_EVENTS,
                        label: 'Wydarzenia z cyklu',
                        children: (
                            <EventsList
                                location={location}
                                history={history}
                                predefinedQuery={{
                                    eventCycleId: cyclicEvent.id,
                                    orderBy: 'future',
                                }}
                                header={{
                                    visible: false,
                                }}
                            />
                        ),
                    }]}
                />
                <ModalExtendCyclicEvent
                    location={location}
                    history={history}
                    isOpen={isModalExtendCycleVisible}
                    onClose={() => this.setState({ isModalExtendCycleVisible: false })}
                    onSuccess={() => this.setState({ isModalExtendCycleVisible: false })}
                    data={extendedCyclicEvent}
                />
            </StyledComponent>
        );
    }
}

